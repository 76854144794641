/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_mobile_analytics_app_id": "cd72ba8d3ed44da986f63e0a6aa6753f",
    "aws_mobile_analytics_app_region": "eu-central-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "cd72ba8d3ed44da986f63e0a6aa6753f",
            "region": "eu-central-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://fd6bzxtbrjcq5bhbrqlzx72v4i.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_appsync_apiKey": "da2-yvjdrbwfhba4xhxtmbvdnxrflm",
    "aws_cognito_identity_pool_id": "eu-central-1:886ef7c0-f063-4050-b3f8-bd27be4f6b0c",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_P25OFVQBN",
    "aws_user_pools_web_client_id": "3rmb5mqhlcdttsmajet4buhltp",
    "oauth": {
        "domain": "cosmate-dev.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "exp://192.168.100.12:8081/,cosmate://",
        "redirectSignOut": "exp://192.168.100.12:8081/,cosmate://",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "cosmate-app-bucket164143-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
